<template>
	<div style="width: 1200px;">
  <div class="certificate-box" style="width: 1200px;">
    <div class="cer-l">
      <span class="cer-logo"></span>
      <div class="ewm-box">
        <span class="ewm-img">
          <img :src="ewmimg" />
        </span>
        <p>下载扫码预览</p>
      </div>
    </div>
    <div class="cer-r">
      <div class="cer-data-info">
        <p class="cer-id">证书编号：<span>ABCDKFJ,-DMKSMD222326</span></p>
        <ul class="cer-data-ul">
          <li><span>姓名：</span>{{ info.trueName }}</li>
          <li><span>性别：</span>{{ info.sex }}</li>
          <li><span>出生日期：</span>{{ info.birthday | dateFormat('YYYY年MM月DD日') }}</li>
          <li><span>项目名称：</span>{{ info.projectList.map(project => project.projectName).join(' ') }}</li>
          <!-- <li><span>传承人级别：</span>省级级一级</li> -->
          <li><span>传承人认定时间：</span>{{ info.identifyTime | dateFormat('YYYY年MM月DD日') }}日</li>
          <li><span>打印日期：</span>{{ info.printTime | dateFormat('YYYY年MM月DD日') }}</li>
          <li>
            <span>数字报告有效期：</span>{{ info.identifyTime | dateFormat('YYYY年MM月DD日') }} -
            {{ info.expireTime | dateFormat('YYYY年MM月DD日') }}
          </li>
        </ul>
        <p class="cer-zhu">以上情况属实，专此认证。</p>
        <div class="cer-img">
          <span class="img-border">
            <img v-if="info.personalImg" :src="info.personalImg" />
            <span v-else>照<br />片</span>
          </span>
        </div>
      </div>
      <div class="cer-luo">
        <p>南平市非物质文化遗产</p>
        <p>保护中心（公章）</p>
      </div>
      <span
        class="cer-zhang"
        :style="{
          backgroundImage: 'url(' + cer + ')',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: '100% 100%'
        }"
      ></span>
      <h5 class="cer-tit"></h5>
      <h5 class="cer-bottom"></h5>
    </div>
  </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ewmimg: require('../assets/images/webcat.png'),
	  cer: require('../assets/images/cer-zhang.png'),
      info: {
        trueName: '',
        sex: '',
        projectList: [],
        printTime: '',
        personalImg: '',
        identifyTime: '',
        birthday: '',
        expireTime: ''
      }
    }
  },
  methods: {
    getInheritorInfo() {
      this.axios.get('/api/portal/inheritor/auth/current/certificate').then(res => {
        if (!res.data.projectList) res.data.projectList = []
        this.info = res.data
      })
    }
  },
  mounted() {
    this.getInheritorInfo()
  }
}
</script>
<style lang="scss" scoped></style>

<template>
  <div class="he-r dynamic-margin" style="background: #ffffff!important;padding-bottom: 0!important;">
    <div class="dynamic-con">
      <h5 class="he-tit">
        <i class="tit-back"></i>
        <span>私信邮件详情</span>
        <div class="tit-btn-box mail-btn-boxs">
          <el-button class="main-tit-btn">回复</el-button>
          <el-button class="main-tit-btn">转发</el-button>
          <el-button class="main-tit-btn">删除</el-button>
        </div>
      </h5>
      <div class="main-info-content">
        <div class="mail-tit-box">
          <h5 class="mail-tit">南平市非物质文化遗产传承人审核通知</h5>
          <div class="mail-info-tbox">
            <span
              class="mail-img"
              :style="{ background: 'url(' + mailImg + ') no-repeat center', backgroundSize: 'cover' }"
            ></span>
            <div class="mian-info-con">
              <span
                >发件人：南平市非物质文化遗产保护中心<i
                  class="level-icon"
                  :style="{ background: 'url(' + level + ') no-repeat center', backgroundSize: 'cover' }"
                ></i
              ></span>
              <span>时 间：2020年10月29日 10：35</span>
            </div>
          </div>
        </div>

        <div class="mail-message-box">
          <div class="mail-message-con">
            <p>尊敬的祝石梁先生，您好!</p>
            <p>
              您在
              2020年10月28日，进行了非物质文化遗产传承人的在线认证申请。经过我中心的审核识别，情况属实。现您已经通过非物质文化遗产传承人认证，我们为您颁发【南平市非物质文化遗产项目代表性传承人身份认证数字报告】认证书，感谢您在南平市非物质文化遗产事业上做的贡献。
            </p>
            <div class="mail-certificates">
              <certificate class="tranform-limi"></certificate>
            </div>
          </div>
          <div class="mail-message-bottom">
            <p>南平市非物质文化遗产保护中心</p>
            <p>2020年10月29日</p>
          </div>
        </div>
        <div class="next-mail-box">
          <p>
            <span>下一封未读：</span>
            <span>老师您好，我是南平市xxx电视台的一名记者想采访欧斯和</span>
          </p>
          <div class="tit-btn-box mail-btn-boxs">
            <el-button class="main-tit-btn">上一封</el-button>
            <el-button class="main-tit-btn">下一封</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import certificate from '../../components/certificate'
export default {
  components: {
    certificate
  },

  data() {
    return {
      mailImg: require('../../assets/images/icon3.png'),
      level: require('../../assets/images/level.png')
    }
  }
}
</script>
